module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Capriati SA","short_name":"undefined","description":"Société de peinture en bâtiments de la famille Capriati. Entreprise familiale basée à Rolle et travaillant sur la région de \"La Côte\" dans le canton de Vaud (CH)","start_url":"/","background_color":"#fff","theme_color":"#EE332D","display":"standalone","icon":"src/assets/images/icons/favicon.png","icons":[{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/favicon.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"maskable"},"crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
